import React, { useEffect, useState } from 'react';
import {
	Layout,
	Menu,
	Row,
	Col,
	Button,
	Typography,
	Tooltip,
	Modal,
	Space,
} from 'antd';
import _ from 'lodash';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
	Link,
	Route,
	Switch,
	Redirect,
	useLocation,
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Routes } from './routes/routes';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction } from './redux';
import Home from './Components/Home/Home';
import Spinner from './Components/Spinners/Spinner';

const { Header, Content, Footer } = Layout;

export function App({ history }) {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const { Title, Text } = Typography;
	const [selectedKey, setSelectedKey] = useState(['Home']);
	const { confirm } = Modal;
	const location = useLocation();
	function showNew() {
		confirm({
			title: "What's New (19/01/2025)",
			icon: <ExclamationCircleOutlined />,
			content: (
				<Space direction='vertical'>
					<Text type='success'>
						- Make past appointments transparent (Feature)
					</Text>
					{/* <Text type='danger'>
						- Fixed Calendar issue for showing past
						appointments(Bug fixed)
					</Text> */}
				</Space>
			),
			centered: true,
		});
	}
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	useState(() => {
		const currentPath = location.pathname;
		for (let i = 0; i < Routes.length; i++) {
			if (Routes[i].path.includes(currentPath)) {
				if (
					Routes[i].name != null ||
					Routes[i].name != undefined
				) {
					setSelectedKey(
						capitalizeFirstLetter(Routes[i].name)
					);
				}
			}
		}
	}, [location]);
	const routeComponent = Routes.map(
		({ path, component: Component }) => (
			<Route
				key={path}
				path={path}
				exact={true}
				render={(props) => <Component {...props} />}
			/>
		)
	);
	const renderMenuItems = Routes.filter(
		(route) => route.name !== undefined
	)
		.filter((route) => {
			const matchedRoles = _.intersection(
				route.roles,
				user?.roles
			);

			return matchedRoles.length >= 1;
		})
		.map(({ path, name, target }) => (
			<Menu.Item
				title={capitalizeFirstLetter(name)}
				key={capitalizeFirstLetter(name)}>
				<Link to={path} target={target}>
					{capitalizeFirstLetter(name)}
				</Link>
			</Menu.Item>
		));
	if (user == null) {
		return <Spinner />;
	} else {
		return (
			<Layout style={{ height: '100vh' }}>
				<Header
					style={{
						position: 'fixed',
						zIndex: 1,
						width: '100%',
						color: 'white',
					}}>
					<div className='logo' />
					<Menu
						style={{ display: 'inline-block' }}
						theme='dark'
						mode='horizontal'
						selectedKeys={selectedKey}
						onSelect={({
							item,
							key,
							keyPath,
							selectedKeys,
							domEvent,
						}) => {
							console.log(key);
							setSelectedKey([key]);
						}}>
						{renderMenuItems}
					</Menu>
					<Row style={{ float: 'right' }} gutter={16}>
						<Col span={10}>
							<Tooltip
								placement='bottomLeft'
								title='Click to see Whats new'>
								<Title
									onClick={showNew}
									level={5}
									style={{
										marginTop: '1em',
										color: 'white',
									}}>
									v1.5
								</Title>
							</Tooltip>
						</Col>
						<Col span={6}>
							<Button
								onClick={() => {
									dispatch(logoutAction(history));
								}}
								type='primary'>
								Logout
							</Button>
						</Col>
					</Row>
				</Header>
				<Content
					style={{
						marginTop: '8vmin',
					}}>
					<Switch>
						<Route
							exact
							path='/'
							render={() => <Redirect exact to='/home' />}
						/>
						{routeComponent}
						<Route
							render={() => <span>Invalid route</span>}
						/>
					</Switch>
				</Content>
			</Layout>
		);
	}
}
