import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import {
	Calendar,
	momentLocalizer,
} from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Api from '../../helpers/api';
import {
	toggleAppointmentModal,
	changeCalendarView,
	getCalendarData,
	toggleDataError,
} from '../../redux';
import { useCookies } from 'react-cookie';
import AppointmentModal from './AppointmentModal';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const localizer = momentLocalizer(moment); // or globalizeLocalizer
const DnDCalendar = withDragAndDrop(Calendar);

export default function Calander({ history }) {
	// dayjs.extend(utc);
	// dayjs.extend(timezone);
	// const date = dayjs('2021-08-29T23:18:49.837Z');

	const [startEndDates, setStartEndDates] = useState({
		startDate: getStartAndEnd(true, 8, 50, 0),
		endDate: getStartAndEnd(false, 21, 5, 0),
	});
	const currentView = useSelector(
		(state) => state.appointments.currentView
	);
	const calendarData = useSelector(
		(state) => state.appointments.calendarData
	);
	// const [data, setData] = useState([]);

	const dispatch = useDispatch();
	// const [currentView, setCurrentView] = useState('week');
	const [minMax, setMinMax] = useState({
		min: getStartAndEnd(true, 9, 0, 0),
		max: getStartAndEnd(false, 21, 0, 0),
	});

	const [slotInfo, setSlotInfo] = useState({
		isNew: true,
		_id: '',
		patient_Id: '',
		startDate: new Date(),
		endDate: new Date(),
		PatientFirstName: '',
		PatientLastName: '',
		userId: '',
		doctorFirstName: '',
		doctorLastName: '',
	});

	function getStartAndEnd(min, hr, m, sec) {
		var now = new Date();

		if (min) {
			now.setHours(hr);
			now.setMinutes(m);
			now.setSeconds(sec);
			return now;
		} else {
			now.setHours(hr);
			now.setMinutes(m);
			now.setSeconds(sec);
			return now;
		}
	}
	const gatData = () => {
		dispatch(
			getCalendarData({
				startDate: startEndDates.startDate,
				endDate: startEndDates.endDate,
				history,
			})
		);
	};
	useEffect(() => {
		gatData();
	}, [startEndDates, currentView]);
	// function resizeEvent({ start, end }) {
	// 	console.log(start, end);
	// }
	function moveEvent({ event, start, end, isAllDay }) {
		const api = new Api();
		api
			.init()
			.patch('/protected/appointments/updateappointment', {
				appointmentId: event.id,
				appointmentStartDate: dayjs(start),
				appointmentEndDate: dayjs(end),
			})
			.then((responseData) => {
				if (responseData && responseData.status === 200) {
					gatData();

					message.success(
						'Appointment updated successfully'
					);
				} else {
					message.error('Unable to update Appointment');
				}
			})
			.catch((err) => {
				if (err.response.data.errList.message) {
					console.log(err.response.data.errList.message);
					message.error(err.response.data.errList.message);
				} else {
					message.error('Unable to update Appointment');
				}
			});
	}
	function getStylesForAppointments() {}
	return (
		<>
			<DnDCalendar
				popup
				// resizable={true}
				views={['month', 'week', 'day']}
				selectable
				localizer={localizer}
				events={calendarData}
				// onEventResize={resizeEvent}
				onView={(view) => {
					// console.log(view);
					dispatch(changeCalendarView(view));
				}}
				onSelectSlot={(slotInfo) => {
					setSlotInfo({
						isNew: true,
						_id: null,
						patient_Id: null,
						startDate: slotInfo.start,
						endDate: slotInfo.end,
						PatientFirstName: null,
						PatientLastName: null,
						userId: '',
						doctorFirstName: '',
						doctorLastName: '',
					});
					dispatch(toggleAppointmentModal());
					// setModalVisibility(!modalVisibility);
				}}
				onSelectEvent={(event, e) => {
					setSlotInfo({
						isNew: false,
						_id: event.id,
						patient_Id: event.patient_Id,
						startDate: event.startDate,
						endDate: event.endDate,
						PatientFirstName: event.title.split(' ')[0],
						PatientLastName: event.title.split(' ')[2],
						userId: event.userId,
						doctorFirstName: event.firstName,
						doctorLastName: event.lastName,
					});
					dispatch(toggleAppointmentModal());
					// setModalVisibility(!modalVisibility);
				}}
				onRangeChange={(dates) => {
					if (!Array.isArray(dates)) {
						// month
						const startNow = new Date(dates.start);
						startNow.setHours(8);
						startNow.setMinutes(50);
						startNow.setSeconds(0);
						const endNow = new Date(dates.end);
						endNow.setHours(21);
						endNow.setMinutes(5);
						endNow.setSeconds(0);
						setStartEndDates({
							startDate: startNow,
							endDate: endNow,
						});
					} else {
						if (dates.length > 1) {
							// Week
							const startNow = new Date(dates[0]);
							startNow.setHours(8);
							startNow.setMinutes(50);
							startNow.setSeconds(0);
							const endNow = new Date(
								dates[dates.length - 1]
							);
							endNow.setHours(21);
							endNow.setMinutes(5);
							endNow.setSeconds(0);
							setStartEndDates({
								startDate: startNow,
								endDate: endNow,
							});
						} else {
							// day
							const startNow = new Date(dates[0]);
							startNow.setHours(8);
							startNow.setMinutes(50);
							startNow.setSeconds(0);
							const endNow = new Date(dates[0]);

							endNow.setHours(21);
							endNow.setMinutes(5);
							endNow.setSeconds(0);

							setStartEndDates({
								startDate: startNow,
								endDate: endNow,
							});
						}
					}
					gatData();
				}}
				// onNavigate={(date) => {
				// 	console.log(date);
				// }}
				min={minMax.min}
				max={minMax.max}
				onEventDrop={moveEvent}
				defaultView={currentView}
				startAccessor='startDate'
				endAccessor='endDate'
				style={{ height: 650, color: 'grey' }}
				eventPropGetter={(
					event,
					start,
					end,
					isSelected
				) => {
					let now = dayjs();
					let pastAppointment = now.isAfter(end);
					let newStyle = {
						backgroundColor: event.assignedColor,
						opacity: pastAppointment ? 0.5 : 1,
					};
					return {
						style: newStyle,
					};
				}}
			/>
			<AppointmentModal
				slotInfo={slotInfo}
				// visibility={modalVisibility}
				// setModalVisibility={setModalVisibility}
				gatData={gatData}
			/>
		</>
	);
}
